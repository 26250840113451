import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import AssetModal from 'components/molecules/AssetModal';
import Banner from 'components/molecules/Banner';
import Seo from 'components/atoms/Seo';

const Gallery = ({
  data: {
    contentfulGallery: {
      videos,
      images,
      videosLabel,
      imagesLabel,
      banner: { bannerSlide },
    },
  },
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);

  const assetVariants: Variants = {
    hidden: {
      x: -50,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const headingVariants: Variants = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.5,
      },
    },
  };

  return (
    <div className="gallery">
      <Seo title="Galeria" />
      <Banner slides={bannerSlide} />
      <Container>
        {images ? (
          <>
            <motion.h2
              className="gallery__heading"
              variants={headingVariants}
              initial="hidden"
              animate="visible"
            >
              {imagesLabel}
            </motion.h2>
            <div className="gallery__asset-wrapper">
              {images.map((image: CommonTypes.ImageType, i: number) => (
                <motion.button
                  onClick={() => setActiveImageIndex(i)}
                  key={image.altText}
                  type="button"
                  variants={assetVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ delay: 0.2 * i, duration: 0.4 }}
                >
                  <GatsbyImage
                    image={image.image.gatsbyImageData}
                    alt={image.altText}
                    className="gallery__asset"
                  />
                </motion.button>
              ))}
            </div>
          </>
        ) : null}
        {videos ? (
          <>
            <h2 className="gallery__heading">{videosLabel}</h2>
            <div className="gallery__asset-wrapper">
              {videos.map((video) => (
                <video key={video.file.fileName} controls className="gallery__asset">
                  <source src={video.file.url} />
                  <track kind="captions" src="subtitles_en.vtt" srcLang="en" />
                </video>
              ))}
            </div>
          </>
        ) : null}
      </Container>
      {activeImageIndex !== null ? (
        <AssetModal
          images={images}
          activeImageIndex={activeImageIndex}
          setActiveImageIndex={setActiveImageIndex}
        />
      ) : null}
    </div>
  );
};

export const query = graphql`
  {
    contentfulHomePage {
      backgroundImage {
        gatsbyImageData(formats: WEBP)
      }
    }
    contentfulGallery {
      videosLabel
      imagesLabel
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      # videos {
      #   file {
      #     fileName
      #     url
      #   }
      # }
      images {
        image {
          gatsbyImageData(formats: WEBP)
        }
        altText
      }
    }
  }
`;

export default Gallery;
