import React, { FC, useState } from 'react';
import useKeypress from 'react-use-keypress';
import { GatsbyImage } from 'gatsby-plugin-image';
import FocusTrap from 'focus-trap-react';
import { motion, Variants } from 'framer-motion';

import { ICONS_SVG } from 'components/atoms/IconsSVG';

import { getIconSvg } from 'utils/getIconSvg';

import { AssetModalProps } from './models.d';

const AssetModal: FC<AssetModalProps> = ({ images, setActiveImageIndex, activeImageIndex }) => {
  const [imageIndex, setImageIndex] = useState<number | null>(activeImageIndex);

  const galleryVariants: Variants = {
    initial: {
      x: '-50%',
      y: '-50%',
      scale: 0,
    },
    final: {
      x: '-50%',
      y: '-50%',
      scale: 1,
      transition: {
        delay: 0.2,
        duration: 0.4,
      },
    },
  };

  const handleLeft = () => {
    if (imageIndex !== null) {
      setImageIndex(imageIndex === 0 ? images.length - 1 : imageIndex - 1);
    }
  };

  const handleRight = () => {
    if (imageIndex !== null) setImageIndex(imageIndex === images.length - 1 ? 0 : imageIndex + 1);
  };

  useKeypress(['ArrowLeft', 'ArrowRight', 'Escape'], (event) => {
    if (event.key === 'ArrowLeft') {
      handleLeft();
    } else if (event.key === 'ArrowRight') {
      handleRight();
    } else if (event.key === 'Escape') {
      if (setActiveImageIndex) setActiveImageIndex(null);
    }
  });

  return (
    <FocusTrap>
      <div className="asset-modal">
        <div className="asset-modal__backdrop" />
        {imageIndex !== null ? (
          <motion.div
            className="asset-modal__image-wrapper"
            variants={galleryVariants}
            initial="initial"
            animate="final"
          >
            <button
              type="button"
              onClick={() => setActiveImageIndex && setActiveImageIndex(null)}
              className="asset-modal__close"
              aria-label="close button"
            >
              {getIconSvg(ICONS_SVG.VARIANT.CROSS)}
            </button>

            <GatsbyImage
              image={images[imageIndex]?.image.gatsbyImageData}
              alt={images[imageIndex]?.altText}
              className="asset-modal__image"
            />
          </motion.div>
        ) : null}
        <div className="asset-modal__nav-wrapper">
          <button
            onClick={() => handleLeft()}
            type="button"
            className="asset-modal__nav-arrow"
            aria-label="previous button"
          >
            {getIconSvg(ICONS_SVG.VARIANT.ARROW_LEFT)}
          </button>
          <button
            onClick={() => handleRight()}
            type="button"
            className="asset-modal__nav-arrow"
            aria-label="next button"
          >
            {getIconSvg(ICONS_SVG.VARIANT.ARROW_RIGHT)}
          </button>
        </div>
      </div>
    </FocusTrap>
  );
};

export default AssetModal;
